<template>
  <div>
    <div>
        <div class="header_common_box" >
            <v-header :params="{ title, leftIcon: true }"></v-header>
        </div>
        <div class="head">
            <img :src="masterImg"/>
            <p>{{masterName}}
                <span>({{ this.lotteryKind==='7' ?'七星彩':'排列五' }})</span>
                
            </p>
            <button @click="gotopayment">马上订阅</button>
            <div class="message">
                <p>{{masterinfo.personalProfile}}</p>
            </div>

            
        </div>
        <div class="body">
            <div>
                <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="onLoad"
                >
                    <div class="item" v-for="(item, index) in list" :key="index" @click="gotoDetail(item.artId)">
                        <!-- <img :src="item.headImgUrl"/> -->
                        <van-image class="img"
                        width="2.6rem"
                        height="2.3rem"
                        fit="fill"
                        :src="item.headImgUrl"
                        />
                        <div class="content">
                            <p>{{item.artTitle}}</p>
                            <p class="date">
                                <span v-if="item.lotteryKind===7">七星彩</span>
                                <span v-else>排列五&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <span>{{ item.artDate| setDateMMdd }}</span>
                            </p>
                        </div>
                        <div class="price">
                            <p v-if="item.price===0">免费</p>
                            <p v-else>月亮：{{ item.price/100 }}</p>
                        </div>
                    </div>
                </van-list>
            </div>
        </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import vHeader from "@/components/header.vue";
import Moment from 'moment';
export default {
    components: {
        vHeader
    },
    filters: {
        setDateMMdd(vle) {
        if (vle) { return Moment(vle).format('yy-MM-DD') }
        return '';
        }
    },
    data(){
        return{
            title: this.$route.meta.title,
            productId: this.$route.query.productId,
            masterImg: this.$route.query.masterImg,
            masterName: this.$route.query.masterName,
            lotteryKind: this.$route.query.lotteryKind,
            access_token: this.$store.state.common.loginInfo.access_token,
            userId: this.$store.state.common.loginInfo.userId,
            masterinfo: {},
            list: [],
            loading: false,
            finished: false,
            pagination: {
                pageNo: 1,
                pageSize: 10,
            },
        }
    },
    methods:{
        gotoDetail(art_id){
            this.$router.push({
                name:'contentDetail',
                query:{
                    artId:art_id
                }
            })
        },
        gotopayment(){
            this.$router.push({
                name:'commonvip',
                query:{
                    userid:this.userId,
                    price:this.masterinfo.vipPrice,
                    access_token:this.access_token,
                    mb_kind:this.masterinfo.kindId,
                    related_id:this.productId
                }
            })
        },
        async onLoad(){
            let data = {
                productId: this.productId,
                pageNo: this.pagination.pageNo,
                pageSize: this.pagination.pageSize,
                sortBy: 'art_date desc, sort_index',
                systemId: 100001, 
                }
                const res = await this.$HTTP.post(this, 'API_ARTICLE_LISTS', data, {}, true)
               
                this.loading = false
                if (res.statusCode === 200) {
                    // console.log('大师的历史帖子信息2：',res)
                    this.pagination.pageNo += 1
                    this.list=this.list.concat(res.data)
                    
                }
                if (this.list.length >= res.totalSize * 1) {
                        this.finished = true;
                    }
        },
        async getmasterinfo(){
            let data = {
                relation_id: this.productId,
                kind_id: 1,
                system_id: 100001, 
                }
            const res = await this.$HTTP.get(this, this.$API['API_PROFILES_MASTER'], data, {headers: { access_token: this.$store.state.common.loginInfo.access_token } }, true)
                                if(res.statusCode == 200){
                                    console.log('预测资料大师信息',res)
                                    this.masterinfo=res.data
                                }
        }
    },
    mounted(){
        // this.getinfo()
        this.getmasterinfo()
    }
}
</script>

<style lang="less" scoped>
*{
    margin: 0;
    padding: 0;
}
.head{
    // position:fixed;
    // top: 0;
    // z-index: 1;
    width: 100%;
    background-color: rgba(244, 24, 24, 0.954);
    display: inline-block;
    img{
        float: left;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        margin: 0.5rem 0 0.2rem 0.8rem;
    }
    p{
        color: white;
        margin: 0.8rem 0.4rem;
        float: left;
        font-size: 0.5rem;
        font-weight: 900;
        span{
            font-size: 0.4rem;
        }
    }
    button{
        width: 2rem;
        height: 1rem;
        float: left;
        border-radius: 13px;
        border: none;
        font-size: 0.4rem;
        font-weight: 900;
        color: #ea6d0d;
        background-color: rgba(247, 243, 4, 0.878);
        margin: 0.7rem 0.6rem;
        
    }
    .message{
        width: 100%;
        height: 1rem;
        p{  
            width: 96%;
            padding: 0.2rem;
            line-height: 0.6rem;
            margin: 0;
            font-weight: normal;
            font-size: 0.4rem;
            color: black;
            border-radius: 10px 10px 0 0;
            background-color: white;

        }
    }
}
.body{
    // z-index: 1;

    // position: relative;
    // top: 5rem;
    width: 100%;
    border-radius: 10px;
    margin: 0;
    p{
        font-size: 0.4rem;
        font-weight: normal;
    }
    .item{
        width: 100%;
        background-color: white;
        margin: 0;
        display: inline-block;
        .img{
            float: left;
            padding: 0.1rem;
        }
        .content{
            
            padding: 0.2rem;
            float: left;
            width: 4.8rem;
            .date{
                opacity: 0.5;
                margin: 0.3rem;
            }

        }
        .price{
            float: left;
            width: 2rem;
            p{
                color: #ea6d0d;
                font-size: 0.3rem;
                padding: 1rem 0.1rem;
            }

        }
    }
    
}

</style>